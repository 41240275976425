import * as React from 'react';
import { StyledInView } from './in-view-element.styles';
import { useObserverContext } from '../global/contexts/observer.context';
import { TransitionStyles } from '../global/animation.styles';

export interface IInViewElementProps {
    transitionStyle?: TransitionStyles;
}

export const InViewElement: React.FC<IInViewElementProps> = props => {
  const { inViewObserver, registerElementInView, unregisterElementInView } = useObserverContext();
  const inViewElementRef = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    inViewElementRef.current && registerElementInView && registerElementInView(inViewElementRef.current as HTMLElement);

    return () => {
      inViewElementRef.current && unregisterElementInView && unregisterElementInView(inViewElementRef.current as HTMLElement);
    };
}, [inViewObserver, inViewElementRef]);

  return (
    <StyledInView transitionStyle={props.transitionStyle} ref={inViewElementRef}>
        {props.children}
    </StyledInView>
  );
};
